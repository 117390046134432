import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import MQTTComponent from '../components/MQTTComponentESS'; // Ajuste o caminho conforme necessário
import Header from '../components/Header';

// Definindo os tipos para as chaves do estado de entrada
type InputStateKeys = 
  | 'entrada_digital_1' 
  | 'entrada_digital_2'
  | 'entrada_digital_3'
  | 'analogica_1'
  | 'analogica_2'
  | 'analogica_3'
  | 'analogica_4'
  | 'analogica_5'
  | 'analogica_6'
  | 'temperatura_1'
  | 'temperatura_2'
  | 'temperatura_3';

  const descriptions: Record<InputStateKeys, string> = {
    entrada_digital_1: "Estado atual da máquina: Ligada ou Desligada",
    entrada_digital_2: "Estado atual da máquina: Ligada ou Desligada",
    entrada_digital_3: "Estado atual da máquina: Ligada ou Desligada",
    analogica_1: "Valor da entrada analógica 1",
    analogica_2: "Valor da entrada analógica 2",
    analogica_3: "Valor da entrada analógica 3",
    analogica_4: "Valor da entrada analógica 4",
    analogica_5: "Valor da entrada analógica 5",
    analogica_6: "Valor da entrada analógica 6",
    temperatura_1: "Temperatura em °C",
    temperatura_2: "Temperatura em °C",
    temperatura_3: "Temperatura em °C",
  };
  

const Broker: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Obtém o ID da URL
  const [inputStates, setInputStates] = useState<Record<InputStateKeys, string>>({
    entrada_digital_1: "00",
    entrada_digital_2: "00",
    entrada_digital_3: "00",
    analogica_1: "00",
    analogica_2: "00",
    analogica_3: "00",
    analogica_4: "00",
    analogica_5: "00",
    analogica_6: "00",
    temperatura_1: "00",
    temperatura_2: "00",
    temperatura_3: "00",
  });

  // Função para adicionar alertas
  const addAlert = (message: string) => {
    const updates = parseMessage(message);
    if (updates) {
      setInputStates((prevStates) => ({
        ...prevStates,
        ...updates,
      }));
      checkTemperature(message);
    }
  };
  
  const parseMessage = (message: string): Partial<Record<InputStateKeys, string>> | null => {
    console.log("Mensagem recebida:", message); // Log da mensagem recebida
    const updates: Partial<Record<InputStateKeys, string>> = {};

    // Expressões regulares separadas para cada tipo de entrada
    const digitalInputRegex = /(entrada_digital_\d+)\s+(desligado|ligado)/g;
    const analogInputRegex = /(analogica_\d+):\s*([\d.]+)/g;
    const temperatureRegex = /(temperatura_\d+):\s+([\d]+(?:\.\d{1,2})?)°C/g;

    // Captura entradas digitais
    let match;
    while ((match = digitalInputRegex.exec(message)) !== null) {
        const [_, key, value] = match; // Extrai chave e valor
        console.log(`Correspondência digital encontrada: ${key} = ${value}`); // Log da correspondência
        updates[key as InputStateKeys] = value; // Atribui o valor correspondente
    }

    // Captura entradas analógicas
    while ((match = analogInputRegex.exec(message)) !== null) {
        const [_, key, value] = match; // Extrai chave e valor
        console.log(`Correspondência analógica encontrada: ${key} = ${value}`); // Log da correspondência
        updates[key as InputStateKeys] = value; // Atribui o valor correspondente
    }

    // Captura temperaturas
    while ((match = temperatureRegex.exec(message)) !== null) {
        const [_, key, value] = match; // Extrai chave e valor
        console.log(`Correspondência de temperatura encontrada: ${key} = ${value}`); // Log da correspondência
        updates[key as InputStateKeys] = value; // Atribui o valor correspondente
    }

    return Object.keys(updates).length > 0 ? updates : null;
};


  
  

  const checkTemperature = (message: string) => {
    const temperatureMatch = message.match(/Temperatura: (\d+(\.\d+)?)°C/);
    const temperature = temperatureMatch ? parseFloat(temperatureMatch[1]) : null;

    if (temperature !== null && temperature > 30) {
      showNotification(temperature);
      console.log(`Temperatura acima de 30°C: ${temperature}°C`);
    }
  };

  const showNotification = (temperature: number) => {
    if (Notification.permission === "granted") {
      new Notification("Alerta de Temperatura Alta", {
        body: `Temperatura acima de 30°C: ${temperature}°C`,
      });
    } else {
      console.log("Permissão de notificação não concedida."); // Log se a permissão não for concedida
    }
  };

  useEffect(() => {
    // Solicitar permissão para notificações
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header /> {/* Inclui o Header fixo */}
      <main className="flex-1 p-6">
        <div className="max-w-6xl mx-auto bg-neutral-800 rounded-lg shadow-lg">
          <div className="p-6">
            <h1 className="text-3xl font-bold mb-4 text-center">Dashboard de Alertas - {id}</h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              {/* Componente MQTT para receber mensagens */}
              <MQTTComponent selectedMac={`${id}`} onMessageReceived={addAlert} />

              {/* Exibe os estados das entradas */}
              {Object.entries(inputStates).map(([key, value]) => (
                <div key={key} className="bg-gray-700 rounded-lg p-4 shadow-md flex flex-col justify-between">
                  <h3 className="font-semibold text-lg mb-2">{key.replace(/_/g, ' ')}</h3>
                  <p className="text-sm mb-2">{descriptions[key as InputStateKeys]}</p> {/* Exibe a descrição */}

                  <div className={`text-3xl font-bold ${value === 'desligado' ? 'text-red-500' : 'text-green-500'}`}>
                    {value}
                  </div>
                </div>
              ))}
            </div>

            {/* Botão de teste de notificação */}
            <button
              className="text-white bg-green-600 px-4 py-2 rounded-lg mt-4"
              onClick={() => showNotification(35)} // Testa a notificação
            >
              Testar Notificação
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Broker;
