import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import { useParams } from 'react-router-dom';
import { buscarParametrosPorId, editarParametros, deletarParametros } from '../../http/Esp';
import axios, { AxiosError } from 'axios';


interface Parametro {
  id: number | null;
  parameter_name: string;
  description?: string;
  min_value?: number | null;
  max_value?: number | null;
  status: string;
}

const EditarParametros: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [Error, setError] = useState('');
  const [Success, setSuccess] = useState('');
  const [loading, setLoading] = useState(true);
  const [parametros, setParametros] = useState<Parametro[]>([]);
  const [newParameter, setNewParameter] = useState<Parametro>({
    id: null,
    parameter_name: '',
    description: '',
    min_value: null,
    max_value: null,
    status: 'ligado',
  });
  const [newParameterType, setNewParameterType] = useState('digital'); // Tipo inicial

  useEffect(() => {
    const fetchParams = async () => {
      try {
        const response = await buscarParametrosPorId({ id: Number(id) });
        if (response && Array.isArray(response.parametros)) {
          setParametros(response.parametros);
        } else {
          setError('Dados de parâmetros inválidos.');
        }
        setLoading(false);
      } catch (error) {
        console.error('Erro ao buscar parametros:', error);
        setError('Erro ao buscar parametros. Tente novamente.');
        setLoading(false);
      }
    };
    fetchParams();
  }, [id]);

  const handleInputChange = (index: number, field: string, value: any) => {
    setParametros((prevParametros) =>
      prevParametros.map((parametro, i) =>
        i === index ? { ...parametro, [field]: value } : parametro
      )
    );
  };

  const handleNewParameterChange = (field: string, value: any) => {
    setNewParameter((prev) => ({ ...prev, [field]: value }));
  };

  const handleAddParameter = () => {

    setParametros((prev) => [...prev, newParameter]);
    setNewParameter({
      id: null,
      parameter_name: '',
      description: '',
      min_value: null,
      max_value: null,
      status: 'ligado',
    }); // Resetar o novo parâmetro
  };

  const handleDelete = async (id: number) => {
    setParametros((prev) => prev.filter((parametro) => parametro.id !== id));

    try {
      const response = await deletarParametros(id);

      // Verifica se a resposta existe
      if (!response) {
        setError('Erro ao deletar parâmetro. Tente novamente.'); // Mensagem genérica para erros
        setSuccess(''); // Limpa a mensagem de sucesso
        return;
      }
      
      enum HttpCodeResponse {
        OK = 200,
        INTERNAL_SERVER_ERROR = 500,
        NOT_IMPLEMENTED = 501,
        BAD_GATEWAY = 502,
      }
      // Mapeia os códigos de status para mensagens
      const statusMessages: { [key in HttpCodeResponse]?: string } = {
        [HttpCodeResponse.OK]: response.data.message,  // Mensagem de sucesso
        [HttpCodeResponse.INTERNAL_SERVER_ERROR]: response.data.message,  // Mensagem de erro do servidor
        [HttpCodeResponse.NOT_IMPLEMENTED]: response.data.message,  // Mensagem de erro específica
        [HttpCodeResponse.BAD_GATEWAY]: response.data.message,  // Mensagem de erro específica
      };

      // Verifica a mensagem correspondente
      const message = statusMessages[response.status as HttpCodeResponse];

      if (message) {
        setSuccess(message);
        setError('');
      } else {
        setError('Erro inesperado.'); // Para qualquer outro código
        setSuccess('');
      }
    } catch (error) {
      // Aqui você pode lidar com erros que não vêm da resposta do servidor
      setError('Erro ao editar cliente. Tente novamente.'); // Mensagem genérica para erros
      setSuccess(''); // Limpa a mensagem de sucesso
    }


  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      // Enviar dados para o backend ou processar conforme necessário
      const response = await editarParametros(Number(id), parametros);

      // Verifica se a resposta existe
      if (!response) {
        setError('Erro ao editar parâmetro. Tente novamente.'); // Mensagem genérica para erros
        setSuccess(''); // Limpa a mensagem de sucesso
        return;
      }

      enum HttpCodeResponse {
        OK = 200,
        INTERNAL_SERVER_ERROR = 500,
        NOT_IMPLEMENTED = 501,
        BAD_GATEWAY = 502,
      }
      // Mapeia os códigos de status para mensagens
      const statusMessages: { [key in HttpCodeResponse]?: string } = {
        [HttpCodeResponse.OK]: response.data.message,  // Mensagem de sucesso
        [HttpCodeResponse.INTERNAL_SERVER_ERROR]: response.data.message,  // Mensagem de erro do servidor
        [HttpCodeResponse.NOT_IMPLEMENTED]: response.data.message,  // Mensagem de erro específica
        [HttpCodeResponse.BAD_GATEWAY]: response.data.message,  // Mensagem de erro específica
      };

      // Verifica a mensagem correspondente
      const message = statusMessages[response.status as HttpCodeResponse];


      if (message) {
        setSuccess(message);
        setError('');
      } else {
        setError('Erro inesperado.'); // Para qualquer outro código
        setSuccess('');
      }
    } catch (error) {
      // Aqui você pode lidar com erros que não vêm da resposta do servidor
      setError('Erro ao editar parâmetros. Tente novamente.'); // Mensagem genérica para erros
      setSuccess(''); // Limpa a mensagem de sucesso
    }

  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">

      <div className="flex items-center justify-center py-4">
            {Success && (
              <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                {Success}
              </div>
            )}

            {Error && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {Error}
                </div>
              )}
          </div>


        <div className="max-w-5xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">
            <form onSubmit={handleSubmit} className="space-y-4">
              <h2 className="text-2xl font-bold mt-6 mb-4">Configurações de parâmetros do sensor</h2>

              {loading ? (
                <p>Carregando parâmetros...</p>
              ) : (
                parametros.length > 0 ? (
                  parametros.map((parametro, index) => (
                    <div key={id} className="p-4 bg-neutral-800 rounded-lg">
                      <h3 className="text-lg font-semibold text-gray-200">{parametro.parameter_name}</h3>
                      {/* Botão de exclusão */}
                      <button
                        type="button"
                        onClick={() => handleDelete(parametro.id as number)}
                        className="text-red-500 mt-1"
                      >
                        Excluir
                      </button>

                      {parametro.min_value !== null && parametro.max_value !== null ? (
                        <>
                          <label className="block text-sm font-medium text-gray-300">Valor Mínimo</label>
                          <input
                            type="number"
                            value={parametro.min_value !== null ? parametro.min_value : ''}
                            onChange={(e) => handleInputChange(index, 'min_value', parseFloat(e.target.value))}
                            className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                            placeholder="Valor Mínimo"
                          />

                          <label className="block text-sm font-medium text-gray-300">Valor Máximo</label>
                          <input
                            type="number"
                            value={parametro.max_value !== null ? parametro.max_value : ''}
                            onChange={(e) => handleInputChange(index, 'max_value', parseFloat(e.target.value))}
                            className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                            placeholder="Valor Máximo"
                          />
                        </>
                      ) : (
                        <>
                          <label className="block text-sm font-medium text-gray-300">Status</label>
                          <select
                            value={parametro.status}
                            onChange={(e) => handleInputChange(index, 'status', e.target.value)}
                            className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                          >
                            <option value="ligado">Ligado</option>
                            <option value="desligado">Desligado</option>
                          </select>
                        </>
                      )}
                    </div>
                  ))
                ) : (
                  <p>Nenhum parâmetro encontrado.</p>
                )
              )}

              {Error && <p className="text-red-500">{Error}</p>}
              <button type="submit" className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium">
                Salvar Configurações
              </button>
            </form>

            {/* Seção para adicionar novo parâmetro */}
            <div className="mt-6">
              <h3 className="text-xl font-bold">Adicionar Novo Parâmetro</h3>
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-300">Tipo de Parâmetro</label>
                <select
                  value={newParameterType}
                  onChange={(e) => {
                    setNewParameterType(e.target.value);
                    handleNewParameterChange('parameter_name', e.target.value); // Atualiza o nome do parâmetro com base no tipo
                  }}
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                >
                  <option value="digital">Entrada Digital</option>
                  <option value="analogica">Entrada Analógica</option>
                  <option value="temperatura">Temperatura</option>
                </select>

                <label className="block text-sm font-medium text-gray-300 mt-4">Nome do Parâmetro</label>
                <select
                  value={newParameter.parameter_name}
                  onChange={(e) => handleNewParameterChange('parameter_name', e.target.value)}
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                >
                  <option value="">Selecione um parâmetro</option>
                  <option value="analogica_1">Analogica 1</option>
                  <option value="analogica_2">Analogica 2</option>
                  <option value="analogica_3">Analogica 3</option>
                  <option value="analogica_4">Analogica 4</option>
                  <option value="analogica_5">Analogica 5</option>
                  <option value="analogica_6">Analogica 6</option>
                  <option value="temperatura_1">Temperatura 1</option>
                  <option value="temperatura_2">Temperatura 2</option>
                  <option value="temperatura_3">Temperatura 3</option>
                  <option value="entrada_digital_1">Entrada Digital 1</option>
                  <option value="entrada_digital_2">Entrada Digital 2</option>
                  <option value="entrada_digital_3">Entrada Digital 3</option>
                </select>


                {newParameterType === 'digital' && (
                  <>
                    <label className="block text-sm font-medium text-gray-300 mt-4">Status</label>
                    <select
                      value={newParameter.status}
                      onChange={(e) => handleNewParameterChange('status', e.target.value)}
                      className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                    >
                      <option value="ligado">Ligado</option>
                      <option value="desligado">Desligado</option>
                    </select>
                  </>
                )}

                {newParameterType === 'analogica' && (
                  <>
                    <label className="block text-sm font-medium text-gray-300 mt-4">Valor Mínimo</label>
                    <input
                      type="number"
                      value={newParameter.min_value !== null ? newParameter.min_value : ''}
                      onChange={(e) => handleNewParameterChange('min_value', parseFloat(e.target.value))}
                      className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                      placeholder="Valor Mínimo"
                    />

                    <label className="block text-sm font-medium text-gray-300 mt-4">Valor Máximo</label>
                    <input
                      type="number"
                      value={newParameter.max_value !== null ? newParameter.max_value : ''}
                      onChange={(e) => handleNewParameterChange('max_value', parseFloat(e.target.value))}
                      className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                      placeholder="Valor Máximo"
                    />
                  </>
                )}

                {newParameterType === 'temperatura' && (
                  <>
                    <label className="block text-sm font-medium text-gray-300 mt-4">Temperatura mínima de operação</label>
                    <input
                      type="number"
                      value={newParameter.min_value !== null ? newParameter.min_value : ''}
                      onChange={(e) => handleNewParameterChange('min_value', parseFloat(e.target.value))}
                      className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                      placeholder="Valor Mínimo"
                    />

                    <label className="block text-sm font-medium text-gray-300 mt-4">Temperatura máxima de operação</label>
                    <input
                      type="number"
                      value={newParameter.max_value !== null ? newParameter.max_value : ''}
                      onChange={(e) => handleNewParameterChange('max_value', parseFloat(e.target.value))}
                      className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                      placeholder="Valor Máximo"
                    />
                  </>
                )}

                <button
                  type="button"
                  onClick={handleAddParameter}
                  className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
                >
                  Adicionar Parâmetro
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default EditarParametros;
