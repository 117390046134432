import axios from "axios";
import { useState } from "react";


interface clientesRequest {
    nome: string;
    email: string;
    cpf: string;
    telefone: string;
    endereco: string;
    
}

interface desativarClienteRequest {
    id: number;
}

interface ativarClienteRequest {
    id: number;
}

interface cadastrarClienteRequest {
    name: string;
    cnpj: string;
    address: {
        street: string;
        postal_code: string;
        number: string;
        neighborhood: string;
        id_city: string;
        id_state: string;
    },
    contacts: {
        email: string;
        cellphone: string;
      }
    }

interface clienteRequestId {
    id: number;
}

interface editarClienteRequest {
    id: number;
    name: string;
    cnpj: string;
    address: {
      street: string;
      postal_code: string;
      number: string;
      neighborhood: string;
      id_city: string;
      id_state: string;
    };
    contacts: {
      email: string;
      cellphone: string;
    };
  }

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function buscarEstados() {
    try {
        const response = await axios.get(`${API_BASE_URL}/state`);
        if (response.status === 200) {
            return response.data.states;
        } else {
            throw new Error('Erro ao buscar estados');
        }
    }
    catch (error) {
        console.error('Erro ao buscar estados:', error);
    }
}

export async function buscarCidades() {
    try {
        const response = await axios.get(`${API_BASE_URL}/city`);
        if (response.status === 200) {
            return response.data.cities;
        } else {
            throw new Error('Erro ao buscar cidades');
        }
    }
    catch (error) {
        console.error('Erro ao buscar cidades:', error);
    }
}

export async function buscarClientes(page: number) {
    try {
        const response = await axios.get(`${API_BASE_URL}/clients?page=${page}&limit=5`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao buscar clientes');
        }
    } catch (error) {
        console.error('Erro ao buscar clientes:', error);
    }
}

//buscar clientes sem paginação
export async function buscarClientesSemPaginacao() {
    try {
        const response = await axios.get(`${API_BASE_URL}/clients`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao buscar clientes');
        }
    } catch (error) {
        console.error('Erro ao buscar clientes:', error);
    }
}

export async function cadastrarCliente({ name, cnpj, address, contacts }: cadastrarClienteRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/clients-register`, {
            name,
            cnpj,
            address,
            contacts
        });
        if (response.status === 200) {
            return response.data;
        }else{
            throw new Error('Erro ao cadastrar cliente');
        }
    } catch (error) {
        console.error('Erro ao cadastrar cliente:', error);
    }
}

export async function desativarCliente({ id }: desativarClienteRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/clients-delete/${id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao desativar cliente');
        }
    } catch (error) {
        console.error('Erro ao desativar cliente:', error);
    }
}

export async function ativarCliente({ id }: ativarClienteRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/clients-activate/${id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao ativar cliente');
        }
    } catch (error) {
        console.error('Erro ao ativar cliente:', error);
    }
}

export async function buscarClientePorId({ id }: clienteRequestId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/client-id/${id}`);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Erro ao buscar cliente');
        }
    } catch (error) {
        console.error('Erro ao buscar cliente:', error);
    }
}


export async function editarCliente({ id, name, cnpj, address, contacts }: editarClienteRequest) {
    const { street, postal_code, number, neighborhood, id_city, id_state } = address;
    const { email, cellphone } = contacts;
    console.log('id do cliente:', id);
  
    try {
        const response = await axios.post(`${API_BASE_URL}/client-update/${id}`, { 
            name,
            cnpj,
            address: {
              street,
              postal_code,
              number,
              neighborhood,
              id_city,
              id_state,
            },
            contacts: {
              email,
              cellphone,
            },
        });
      
        return response;
    } catch (error) {
      console.error('Erro ao editar cliente:', error);
    }
  }
  