import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { ativarEsp, buscarEsp, desativarEsp } from '../../http/Esp';

const Esp32: React.FC = () => {
  const [sensors, setSensor] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sensorError, setMacError] = useState('');
  const [sensorSucess, setMacSucess] = useState('');
  // Função para buscar ESPs do backend
  const fetchEsp = async (page: number) => {
    try {
      const response = await buscarEsp(page);
      setSensor(response.sensores);
      setTotalPages(Math.ceil(response.total / 5)); // Atualiza total de páginas com base no total de máquinas
    } catch (error) {
      console.error('Erro ao buscar ESPs:', error);
    }
  };

  // Função para desativar ESP
  const handleDelete = async (id: number) => {
    try {
      await desativarEsp({ id });
      fetchEsp(currentPage);
      setMacSucess('ESP desativado com sucesso');
    } catch (error) {
      console.error('Erro ao desativar ESP:', error);
      setMacError('Erro ao desativar ESP. Tente novamente.');
    }
  };

  // Função para ativar MAC
  const handleActivate = async (id: number) => {
    try {
      await ativarEsp({ id });
      fetchEsp(currentPage);
      setMacSucess('ESP ativado com sucesso');
    } catch (error) {
      console.error('Erro ao ativar ESP:', error);
      setMacError('Erro ao ativar ESP. Tente novamente.');
    }
  };

  // Função para editar ESP
  const handleEdit = (id: number) => {
    // Redireciona para a página de edição
    window.location.href = `/editar_esp32/${id}`;
  };

  // Função para editar parâmetros
  const handleEditParams = (id: number) => {
    // Redireciona para a página de edição de parâmetros
    window.location.href = `/editar_parametros/${id}`;
  };

  useEffect(() => {
    fetchEsp(currentPage);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
      <div className="flex items-center justify-center py-4">
            {sensorSucess && (
              <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                {sensorSucess}
              </div>
            )}

            {sensorError && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {sensorError}
                </div>
              )}
          </div>
        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">ESPs cadastrados</h1>
            <div className="flex gap-x-4">
              <Link
                to="/cadastro_esp32"
                className="text-sm md:text-base lg:text-lg bg-custom-blue text-white hover:bg-blue-700 hover:text-gray-200 py-2 px-4 rounded-lg"
              >
                + ESP32
              </Link>


            </div>

          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">ID</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">MAC</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Máquina</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Cliente</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Status do registro</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Telegram</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Notificações</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Ações</th>

                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {sensors.length > 0 ? (
                  sensors.map((sensors) => (
                    <tr key={sensors.id}>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.id}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.mac}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.machine_name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.client_name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.deleted_at ? "Inativo" : "Ativo"}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.telegram_chat_id}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{sensors.notification_status}</td>

                      <td className="px-4 py-2 text-sm text-gray-100">
                        <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600 mr-2" onClick={() => handleEdit(sensors.id)}>Editar</button>
                        <button className="bg-orange-500 text-white px-3 py-1 rounded-lg hover:bg-orange-600" onClick={() => handleEditParams(sensors.id)}>Parâmetros</button>

                        {sensors.deleted_at ? (
                          <button className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 ml-2" onClick={() => handleActivate(sensors.id)}>Ativar</button>
                        ) : (
                          <button className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2" onClick={() => handleDelete(sensors.id)} >Desativar</button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="px-4 py-2 text-sm text-gray-100 text-center">Nenhum sensor encontrado</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Anterior
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage >= totalPages}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Próximo
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Esp32;