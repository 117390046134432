import axios from "axios";

interface usuariosRequest {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    userRole: string;
    selectedClient: string;
}

interface cadastrarUsuarioRequest {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    userRole: string;
    selectedClient: string;
}

interface desativarUsuarioRequest {
    id: number;
}

interface ativarUsuarioRequest {
    id: number;
}

interface usuarioRequestId {
    id: number;
}

interface editarUsuarioRequest {
    id: number;
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    userRole: string;
    selectedClient: string;
}




const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function buscarUsuarios(page: number) {
    try {

        const response = await axios.get(`${API_BASE_URL}/users?page=${page}&limit=5`);
        
        if(response.status === 200){
            return response.data;
        } else {

            throw new Error('Erro ao buscar usuários');
        }
    } catch (error) {
        console.error('Erro ao buscar usuários:', error);
    }
}

export async function cadastrarUsuario({username, email, password, confirmPassword, selectedClient, userRole }: cadastrarUsuarioRequest) {
    try {
        console.log(userRole, selectedClient);
        const response = await axios.post(`${API_BASE_URL}/users-register`, {
            username,
            email,
            password,
            confirmPassword,
            userRole,
            selectedClient
        });
        console.log(response);
    } catch (error) {
        console.error('Erro ao cadastrar usuário:', error);
    }
}

export async function desativarUsuario({id}: desativarUsuarioRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/users-delete/${id}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao desativar usuário');
        }
    } catch (error) {
        console.error('Erro ao desativar usuário:', error);
    }
}

export async function ativarUsuario({id}: ativarUsuarioRequest) {
    try {
        const response = await axios.post(`${API_BASE_URL}/users-activate/${id}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao ativar usuário');
        }
    } catch (error) {
        console.error('Erro ao ativar usuário:', error);
    }
}

export async function buscarUsuarioPorId({id}: usuarioRequestId) {
    try {
        const response = await axios.get(`${API_BASE_URL}/user-id/${id}`);
        if(response.status === 200){
            return response.data;
        } else {
            throw new Error('Erro ao buscar usuário');
        }
    } catch (error) {
        console.error('Erro ao buscar usuário:', error);
    }
}