import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../../components/Header';
import { Link } from 'react-router-dom';
import { buscarUsuarios } from '../../http/Usuarios';
import { desativarUsuario } from '../../http/Usuarios';
import { ativarUsuario } from '../../http/Usuarios';

const Usuarios: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [userError, setUserError] = useState('');
  const [userSucess, setUserSucess] = useState('');
  const [users, setUsers] = useState<any[]>([]);

  // Função para buscar usuarios do backend
  const fetchClients = async (page: number) => {
    try {
      const response = await buscarUsuarios(page);
      setUsers(response.users);
      setTotalPages(Math.ceil(response.total / 5)); // Atualiza total de páginas com base no total de clientes
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  // Função para desativar usuario
  const handleDelete = async (id: number) => {
    try {
      await desativarUsuario({ id });
      fetchClients(currentPage);
      setUserSucess('Usuário desativado com sucesso');
    } catch (error) {
      setUserError('Erro ao desativar usuário. Tente novamente.');
    }
  };

  // Função para ativar usuário
  const handleActivate = async (id: number) => {
    try {
      await ativarUsuario({ id });
      fetchClients(currentPage);
      setUserSucess('Usuário ativado com sucesso');
    } catch (error) {
      setUserError('Erro ao ativar usuário. Tente novamente.');
    }
  };

  // Função para editar usuário
  const handleEdit = (id: number) => {
    window.location.href = `/editar_usuario/${id}`;
  };


  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">

        <div className="flex items-center justify-center py-4">
          {userSucess && (
            <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
              {userSucess}
            </div>
          )}

          {userError && (
              <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
                {userError}
              </div>
            )}
      </div>



        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6 flex items-center justify-between">

            <h1 className="text-2xl font-bold mb-4">Usuários cadastrados</h1>
            <div className="flex gap-x-4">
              <Link
                to="/cadastro_usuario"
                className="text-sm md:text-base lg:text-lg bg-custom-blue text-white hover:bg-blue-700 hover:text-gray-200 py-2 px-4 rounded-lg"
              >
                + Usuário
              </Link>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Nome</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">E-mail</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Cliente</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Permissão</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Status</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Ações</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {users.length > 0 ? (
                  users.map((user) => (
                    <tr key={user.id}>
                      <td className="px-4 py-2 text-sm text-gray-100">{user.username}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{user.email}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{user.client_name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{user.role}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{user.deleted_at ? 'Inativo' : 'Ativo'}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">
                      <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600" onClick={() => handleEdit(user.id)}>Editar</button>
                      {user.deleted_at ? (
                              <button className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 ml-2" onClick={() => handleActivate(user.id)}>Ativar</button>
                          ) : (
                              <button className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2" onClick={() => handleDelete(user.id)}>Desativar</button>
                          )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="px-4 py-2 text-sm text-gray-100 text-center">Nenhum usuário encontrado</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Anterior
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage >= totalPages}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Próximo
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Usuarios;