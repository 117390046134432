
export function formataCnpj(cnpj: string) {
    return cnpj
      .replace(/\D/g, '') // Remove tudo que não é dígito
      .replace(/^(\d{2})(\d)/, '$1.$2') // Adiciona o primeiro ponto
      .replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3') // Adiciona o segundo ponto
      .replace(/\.(\d{3})(\d)/, '.$1/$2') // Adiciona a barra
      .replace(/(\d{4})(\d)/, '$1-$2') // Adiciona o traço
      .replace(/(-\d{2})\d+?$/, '$1'); // Limita em 14 dígitos
      }
  
export function formataCelular(celular: string) {
    return celular
      .replace(/\D/g, '') // Remove tudo que não é dígito
      .replace(/^(\d{2})(\d)/, '($1) $2') // Adiciona o primeiro parêntese
      .replace(/(\d{5})(\d)/, '$1-$2') // Adiciona o traço
      .replace(/(-\d{4})(\d)/, '$1'); // Limita em 14 dígitos
      }

export function formataCep(cep: string) {
    return cep
      .replace(/\D/g, '') // Remove tudo que não é dígito
      .replace(/^(\d{5})(\d)/, '$1-$2') // Adiciona o traço
      .replace(/(-\d{3})(\d)/, '$1'); // Limita em 9 dígitos
      }

export function formataMac(mac: string) {
  return mac
      .replace(/[^0-9A-Fa-f]/g, '') // Remove tudo que não é dígito ou letra de A a F
      .substring(0, 12) // Limita a 12 caracteres (sem os separadores)
      .replace(/([0-9A-Fa-f]{2})(?=[0-9A-Fa-f])/g, '$1:') // Adiciona o ":" a cada 2 caracteres
      .toUpperCase(); // Transforma em maiúsculas
}
    

export function senhaSegura(senha: string) {
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
  return regex.test(senha);
    }



    