import axios from "axios";

interface loginRequest {
    username: string;
    password: string;
}

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function loginAuth({username, password}: loginRequest) {
    try {
            const response = await axios.post(`${API_BASE_URL}/login`, { username, password});

            if(response.status === 200){
                return response.data;
                
            } else{
                throw new Error('E-mail não encontrado. Tente novamente!');
            }
        } catch (error) {
            console.error('Erro ao fazer login:', error);
            throw new Error('Erro ao se comunicar com o servidor.');

        }

}