  import React from 'react';
  import { Link } from 'react-router-dom';
  import '../styles/Home.css';

  const Home: React.FC = () => {
    return (
      <div className="h-screen flex items-center justify-center bg-gradient-to-r from-gray-500 via-black to-gray-700 animate-gradient-x">
        
        
        <div className="max-w-md px-6 py-8 bg-custom-black-home shadow-md rounded-lg text-center w-[470px] h-[400px] flex justify-center items-center mx-auto ml-8 mr-8">
            <div className="max-w-3xl px-6 text-center space-y-6 ">
              <img src="/logo.png" alt="Logo" className="w-1/2 max-w-xs mx-auto mb-5 block" />

          
              <p className="text-custom-gray text-sm">
              Monitore suas máquinas em tempo real e obtenha informações detalhadas sobre o desempenho e a utilização do seu parque de equipamentos.              </p><br></br>
              <Link to="/login">
                <button className="w-60 px-4 py-2 bg-white text-black rounded-md font-medium transition duration-500">
                  Login
                </button>
              </Link>
              {/* <Link to="/broker">
                <button className="mt-4 px-4 py-2 bg-lime-300 text-lime-950 rounded-lg font-medium transition-transform duration-500 hover:scale-105">
                  MQTT
                </button>
              </Link> */}
            </div>
          </div>
      </div>
    );
  };

  export default Home;
