import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { buscarClientesSemPaginacao } from '../../http/Clientes';
import { buscarEspPorId, editarEsp } from '../../http/Esp';
import { formataMac } from '../../utils/Formatters';

// Definição de tipos para maior segurança
interface Client {
  id: number;
  name: string;
}

interface EspData {
  mac: string;
  client_id: number;
  status: string;
  notificacaoStatus: string;
  telegramGroupId: string;
}

const EditarEsp: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [macAddress, setMacAddress] = useState('');
  const [macError, setMacError] = useState('');
  const [message, setMessage] = useState<{ type: 'success' | 'error' | null; text: string }>({ type: null, text: '' });
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [chatId, setChatId] = useState('');
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [notificacaoStatus, setNotificacaoStatus] = useState(''); // Para o status da notificação

  // Função para formatar o MAC ADDRESS
  const handleMacAddressChange = (e: { target: { value: string; }; }) => {
    const formattedMacAdress = formataMac(e.target.value);
    setMacAddress(formattedMacAdress);
  };

  // Buscar clientes
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await buscarClientesSemPaginacao();
        setClients(response.clients);
      } catch (error) {
        console.error('Erro ao buscar clientes:', error);
      }
    };
    fetchClients();
  }, []);

  // Busca os dados do sensor com o ID da rota
  useEffect(() => {
    const fetchEspData = async () => {
        setLoading(true);
        try {
            const responseEsp = await buscarEspPorId({ id: Number(id) });
            console.log('Dados do ESP:', responseEsp); // Verifique a estrutura aqui

            // Acessa o primeiro elemento do array 'sensores'
            if (responseEsp.sensores.length > 0) {
                const sensorData = responseEsp.sensores[0]; // Acesse o primeiro sensor
                setMacAddress(sensorData.mac); 
                setSelectedClient(sensorData.client_id); 
                setNotificacaoStatus(sensorData.notification_status);
                setChatId(sensorData.telegram_chat_id); 
            }
        } catch (error) {
            console.error('Erro ao buscar ESP:', error);
        } finally {
            setLoading(false);
        }
    };

    if (id) {
        fetchEspData();
    }
}, [id]);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!macRegex.test(macAddress)) {
      setMacError('Formato de MAC address inválido');
      return;
    } else {
      setMacError('');
    }

    try {
      const sensorId = Number(id);
      if (!sensorId) {
        setMacError('ID do sensor inválido');
        return;
      }

      const response = await editarEsp(sensorId, { mac: macAddress, client_id: selectedClient, notificacaoStatus: notificacaoStatus, telegramGroupId: chatId });

      if (response && response.status === 200) {
        setMessage({ type: 'success', text: 'Editado com sucesso' });
        setMacError('');
      } else {
        setMessage({ type: 'error', text: 'Erro ao fazer edição. Tente novamente.' });
      }
    } catch (error) {
      setMessage({ type: 'error', text: 'Erro ao fazer edição. Tente novamente.' });
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">
            <div className="flex items-center justify-center py-4">
              {message.type === 'success' && (
                <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                  {message.text}
                </div>
              )}

              {message.type === 'error' && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {message.text}
                </div>
              )}
            </div>

            <h2 className="text-2xl font-bold mt-6 mb-4">Editar ESP32</h2>

            {loading ? ( // Exibe um indicador de carregamento
              <div className="text-center text-gray-300">Carregando...</div>
            ) : (
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-300">MAC Address (ESP32)</label>
                  <input
                    type="text"
                    className={`mt-1 block w-full bg-neutral-800 border ${macError ? 'border-red-600' : 'border-neutral-700'} rounded-lg p-2.5 text-gray-100`}
                    value={macAddress}
                    onChange={handleMacAddressChange}
                    placeholder="00:00:00:00:00:00"
                    required
                  />
                </div>

                <div>
                  <label htmlFor="client">Vincular a cliente:</label>
                  <select
                    className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                    id="client"
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                  >
                    <option value="">Selecione um cliente</option>
                    {clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {client.name}
                      </option>
                    ))}
                  </select>
                </div>


                <div>
                  <label className="block text-sm font-medium text-gray-300">Chat ID do Telegram</label>
                  <input
                    type="text"
                    className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                    value={chatId}
                    onChange={(e) => setChatId(e.target.value)}
                  />
                </div>


                {/* radio buttom status notificações */}
                <div>
                <label className="block text-sm font-medium text-gray-300">Status de Notificação</label>
                  <div className="flex items-center mt-2">
                  <label className="mr-4">

                  <input
                      id="ativo"
                      name="notificacaostatus"
                      type="radio"
                      checked={notificacaoStatus === 'ativo'}
                      onChange={() => setNotificacaoStatus('ativo')}
                      className="mr-2"
                    />
                      Ativo
                  </label>  
                  <label>
                    
                    <input
                      id="inativo"
                      name="notificacaostatus"
                      type="radio"
                      checked={notificacaoStatus === 'inativo'}
                      onChange={() => setNotificacaoStatus('inativo')}
                      className="mr-2"
                    />
                      Inativo
                  </label>
                  </div>
                </div>




   

                <button type="submit" className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium">
                  Editar
                </button>
              </form>
            )}
          </div>
        </div>
      </main>
    </div>
  );
};

export default EditarEsp;