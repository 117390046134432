import React, { useState } from 'react';
import Header from '../../components/Header';
import axios from 'axios';
import { useEffect } from 'react';
import { senhaSegura } from '../../utils/Formatters';
import { cadastrarUsuario } from '../../http/Usuarios';
import { buscarClientesSemPaginacao } from '../../http/Clientes';

interface Cliente{
  id: number;
  name: string;
  cnpj: string;
  rua: string;
  bairro: string;
  numero: string;
  cep: string;
  email: string;
  telefone: string;
  cidade: string;
  estado: string;
}

const CadastrarUsuario: React.FC = () => {
  
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userError, setUserError] = useState('');
  const [userSucess, setUserSucess] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [clients, setClients] = useState<Cliente[]>([]);
  const [userRole, setUserRole] = useState('');

  useEffect(() => { //buscar clientes
    const fetchClients = async () => {
        try {
            const response = await buscarClientesSemPaginacao();
            setClients(response.clients);
        } catch (error) {
            console.error('Erro ao buscar clientes:', error);
        }
    };
    fetchClients();
}, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    //exigencia de senha segura
    if (!senhaSegura(password)) {
      setUserError('A senha deve conter pelo menos 8 caracteres, uma letra maiúscula, uma letra minúscula, um número e um caractere especial.');
      console.log(senhaSegura(password));
      return;
    }

    if (password !== confirmPassword) {
      setUserError('As senhas não coincidem.');
      return;
    }

    

    try{
      await cadastrarUsuario({username, email, password, confirmPassword, userRole, selectedClient});
      console.log(userRole, selectedClient);
      setUserSucess('Usuário cadastrado com sucesso');
    } catch (error) {
      setUserError('Erro ao cadastrar usuário. Tente novamente.');
    }
      

  };


  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">

        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">

          <div className="p-6">
          <div className="flex items-center justify-center py-4">
              {userSucess && (
                <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                  </svg>
                  {userSucess}
                </div>
              )}

            {userError && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {userError}
                </div>
              )}
          </div>

            <h2 className="text-2xl font-bold mt-6 mb-4">Cadastrar novo usuário</h2>
            <form onSubmit={handleSubmit}>
            <div className="mb-4">
                <input
                  type="text"
                  placeholder="Nome de usuário"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                />
              </div>
              <div className="mb-4">
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  placeholder="Senha"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                />
              </div>
              <div className="mb-4">
                <input
                  type="password"
                  placeholder="Confirmar Senha"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                />
              </div>
              {/* label permissoes (roles) */}
              <div className="mb-4">
                <select onChange={(e) => setUserRole(e.target.value)}
                
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                >
                  <option value="">Selecione uma permissão</option>
                  <option value="admin">Administrador</option>
                  <option value="user">Usuário</option>
                
                </select>
              </div>

              {/* vincular com cliente */}
              <div>
                <label htmlFor="state">Vincular a cliente:</label>
                <select className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  id="state"
                  value={selectedClient}
                  onChange={(e) => setSelectedClient(e.target.value)}
                >
                  <option value="">Selecione um cliente</option>
                  {clients.map((client) => (
                    <option  key={client.id} value={client.id}>
                      {client.name} {/* Use o nome do estado conforme o banco de dados */}
                    </option>
                  ))}
                </select>
              </div>
              <br></br>


              <div className="flex items-center">
                <button
                  type="submit"
                  className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
                >
                  Cadastrar
                </button>
                {/* botão de voltar */}
                <a href="/usuarios" className="w-full bg-gray-600 text-white rounded-lg p-2.5 mt-4 font-medium text-center">Voltar</a>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default CadastrarUsuario;